<template>
  <div>
    <div id="board_title" class="row">
      <div id="main_title">
        서비스 이용 내역
      </div>
      <div id="sub_title">
        회계 자동화 서비스 이용 내역 확인과 함께 오류내용에 대한 신고가 가능합니다.<br><br>
        <span style="font-size: 12px">1) 작업 결과 <span style="color:#e16a00; font-size: 13px">ERROR</span> 는 서비스 모듈의 오류 발생으로 결과파일이 생성되지 않은 경우를 말합니다. (ex.본 서비스의 양식이 아닌 엑셀파일의 사용 등)</span><br>
        <span style="font-size: 12px">2) 작업 결과 <span style="color:#d12800; font-size: 13px">FAIL</span> 은 양식 자료의 사용자 입력값 오류로 결과파일이 생성되지 않은 경우를 말합니다.</span>
      </div>
    </div>
<!--    모바일용 회원이력-->
    <div class="mo_acc_his_body">

      <table class="mo_table pa_table_mo">
        <thead>
        <tr>
          <th rowspan="2">번호</th>
          <th>서비스명</th>
          <th>작업일자</th>
          <th>작업결과</th>
        </tr>
        <tr>
          <th>사용파일</th>
          <th>결과파일</th>
          <th>오류신고</th>
        </tr>
        </thead>
        <tbody v-for="(row,index) in modulearray" :key="row.rowNumber" >
        <tr >
          <td rowspan="2">{{row.rowNumber}}</td>
          <td>{{row.type === "LEASE"? "리스회계처리" : row.type === "DSD"? "DSD 감사/검토보고" : row.type === "DSD_BR"? "DSD 사업보고":row.type === "DSD_AUTO"? "DSD 작성자동": ""}}</td>
          <td style="width:200px !important;">{{row.createDate}}</td>
          <td><span v-if="row.taskStatus === 'SUCCESS'" style="color:#2cb92c;"> SUCCESS</span>
            <span v-if="row.taskStatus === 'ERROR'" style="color:#e16a00;"> ERROR</span>
            <span v-if="row.taskStatus === 'FAIL'" style="color:#d12800;"> FAIL</span></td>
         </tr>
        <tr >
          <td><a v-if="row.type === 'LEASE'" class="dn_document" v-on:click="inputDn($event, row)">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-file-earmark-arrow-up" viewBox="0 0 16 16">
              <path d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707V11.5z"/>
              <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
            </svg>
          </a></td>
          <td><a v-if="row.type ==='LEASE' && row.taskStatus === 'SUCCESS'" class="dn_document" v-on:click="outputDn($event, row)">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-file-earmark-arrow-down" viewBox="0 0 16 16">
              <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z"/>
              <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
            </svg>
          </a></td>
          <td>
            <b-button class="detail_btn" v-if="row.type ==='LEASE' && row.isReported === false && row.taskStatus === 'SUCCESS'" v-on:click="rowClick($event, row)" :index="row.taskId">오류신고</b-button>
            <b-button class="detail_btn_E" v-if="row.type ==='LEASE' && row.isReported === true && row.taskStatus === 'SUCCESS'" disabled>신고완료</b-button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>



<!--    웹용 Grid-->
    <div class="web_grid" style="width:70%; margin-top:90px">
      <table class="w3-table-all pa_table" >
        <thead>
          <tr>
            <th>번호</th>
            <th>서비스명</th>
            <th>작업일자</th>
            <th>작업결과</th>
            <th>사용파일</th>
            <th>결과파일</th>
            <th>오류신고</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row,index) in modulearray" :key="row.rowNumber" >
            <td>{{row.rowNumber}}</td>
            <td>{{row.type === "LEASE"? "리스회계처리" : row.type === "DSD"? "DSD 감사/검토보고" : row.type === "DSD_BR"? "DSD 사업보고":row.type === "DSD_AUTO"? "DSD 작성자동": ""}}</td>
            <td>{{row.createDate}}</td>
            <td><span v-if="row.taskStatus === 'SUCCESS'" style="color:#2cb92c;"> SUCCESS</span>
                <span v-if="row.taskStatus === 'ERROR'" style="color:#e16a00;"> ERROR</span>
                <span v-if="row.taskStatus === 'FAIL'" style="color:#d12800;"> FAIL</span></td>
            <td><a v-if="row.type === 'LEASE'" class="dn_document" v-on:click="inputDn($event, row)">
              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-file-earmark-arrow-up" viewBox="0 0 16 16">
                <path d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707V11.5z"/>
                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
              </svg>
            </a></td>
            <td><a v-if="row.type ==='LEASE' && row.taskStatus === 'SUCCESS'" class="dn_document" v-on:click="outputDn($event, row)">
              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-file-earmark-arrow-down" viewBox="0 0 16 16">
                <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z"/>
                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
              </svg>
            </a></td>
            <td>
              <b-button class="detail_btn" v-if="row.type ==='LEASE' && row.isReported === false && row.taskStatus === 'SUCCESS'" v-on:click="rowClick($event, row)" :index="row.taskId">오류신고</b-button>
              <b-button class="detail_btn_E" v-if="row.type ==='LEASE' && row.isReported === true && row.taskStatus === 'SUCCESS'" disabled>신고완료</b-button>
            </td>
          </tr>
        </tbody>


      </table>

      <b-pagination v-model="currentPage" :total-rows="totalrows" :per-page="perPage" align="center" @click="callPage"></b-pagination>

<!--      <form class="d-flex" role="search" >-->
<!--        <select class="Searchbar form-select" aria-label="Default select example" @change="SearchOptionValue" style="width:25%">-->
<!--          <option value="1" selected>모듈</option>-->
<!--          <option value="2">작업일자</option>-->
<!--        </select>-->
<!--        <input id="searchContext" class="form-control me-2" type="search" placeholder="Search" aria-label="Search" style="width:50%; margin-left:20px">-->
<!--        <button class="search detail_btn btn-outline-success" type="submit" @click="searchArticle">Search</button>-->
<!--      </form>-->
    </div>
  </div>

  <button type="button" class="falseWork" data-bs-toggle="modal" data-bs-target="#reportWindow" style="display:none">모달 실행</button>
<!--  오류신고 모달폼-->
  <div class="modal fade" id="reportWindow" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="report-Comment" aria-hidden="true">
    <div id="reportCMT_window" class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Report Comment</h1>
          <button type="button" class="btn-close E_modalClose" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" style="padding-top: 0px;">
          <form autocomplete="off">
            <div class="Cmt_Title">
              <span>모듈을 통하여 수령한 엑셀파일의 내용 검토 결과</span><br>
              <span>수식 및 산출값에 오류가 발생한 경우 해당 내용에 대하여 신고를 요청할 수 있습니다. </span><br>
              <br>
              <span>운영자는 신고를 접수하여 사유를 수정된 산출물을 제공해드릴 예정입니다.</span>

            </div>

            <label class="history_title form-label">작업 내역</label>

            <div class="detail_used">
              <span>▶ 발생 일시: <span class="workData">{{workDate}}</span></span><br>
              <span>▶ 사용 모듈: <span class="workData">{{workModule}}</span></span><br>
              <span>▶ 사용 파일: <span class="workData">{{workFile}}</span></span>
            </div>

            <textarea id="Cmttextarea" v-model="CmtContext" class="form-control sendMailContext" name="message" placeholder="오류신고 내용을 입력해 주세요.(10자 이상)"></textarea>
            <div style="margin-bottom: 30px; text-align: end">
              ({{conLength}}/ 1000)
            </div>
          </form>

        </div>

        <div class="modal-footer">
          <div class="valid_Footer" style="display: flex;">
            <div id="confirm_Report" type="button" class="modal_btn original col-sm-2" style="margin-left: 10px; " @click="reportModule">보 내 기</div>
            <div id="cancel" class="modal_btn original col-sm-2" data-bs-dismiss="modal" style="margin-left: 10px; cursor:pointer">취   소</div>

          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>

export default {
  name: "Acc_History",
  data() {

    return {
      report_Comp: 0,
      currentPage: this.$route.params.page >1 ? Number(this.$route.params.page) : 1 ,// 현재 페이지
      perPage: 10, // 페이지당 보여줄 갯수
      totalrows: 1,
      modulearray: [],
      loged: $store.state.token, //로그인 사용자 구분
      searchOption:'',
      searchResult: false,
      CmtContext:'',
      conLength:0,

      // 오류신고 이력 가져오기
      workDate:'',
      workModule:'',
      workId:'',
      workFile:''



    };
  },
  watch:{
    // 오류신고 내역 팝업의 글자수 제한
    CmtContext: function () {
      this.conLength = this.CmtContext.length
      if(this.conLength > 1000){
        this.subStringCmt()
      }
    }
  },
  mounted(){
    if(this.$route.params.page >1) {
      this.currentPage = Number(this.$route.params.page)
      // console.log("페이지 2 이상 currentpage="+this.currentPage)
      this.getlist()
    }
    else {
      // console.log("페이지 1 실행")
      this.getlist()
    }
  },

  methods: {

    getlist() {

      const t =this
      const pages = this.currentPage
      const url = '/module/getModuleHistory'
      const utoken =$store.state.token != null ? true : false;
      const getparam = {
        "page": pages-1,
        "size": this.perPage,
        "sort": "string"
      }

      $api.getApiData(url,utoken,successStat,errorStat,getparam);
      function successStat (res) {

        const moduledata = res.data

        t.modulearray = moduledata.content
        t.totalrows = moduledata.totalElements
      }
      function errorStat (error) {
        alert("오류가 발생하였습니다. 페이지를 새로고침 해주세요.")
      }

    },

    callPage(){
      const pages = this.currentPage
      window.location.href = '/accpage/history/'+ pages

    },

    rowClick(e,row) {
      // 클릭 시 행 데이터를 오류신고폼에 표시
      this.workDate = row.createDate
      this.workId = row.taskId
      this.workModule = row.type === "LEASE"? "리스회계처리": ""
      this.workFile = row.inputFileName
      $('.falseWork').click()
    },

    inputDn(e,row) {

      const url = '/module/getResult/'+"input/"+row.resultKey
      const utoken =$store.state.token != null ? true : false;

      $api.getApiData(url,utoken,successStat,errorStat);
      function successStat (res) {
        window.location.href = res.config.url
      }
      function errorStat (error) {
        alert("오류가 발생하였습니다. 페이지를 새로고침 해주세요.")
      }


    },

    outputDn(e,row) {

      const url = '/module/getResult/'+"output/"+row.resultKey
      const utoken =$store.state.token != null ? true : false;

      $api.getApiData(url,utoken,successStat,errorStat);
      function successStat (res) {
        window.location.href = res.config.url
      }
      function errorStat (error) {
        alert("오류가 발생하였습니다. 페이지를 새로고침 해주세요.")
      }

    },

    reportModule() {
      const task = this.workId
      const cmt = $('#Cmttextarea').val()
      let a = localStorage.getItem("access-token")
      if (a != null) {
        if($('#Cmttextarea').val().length > 10 ){

          const url = '/module/reportModule'
          const utoken =$store.state.token != null ? true : false;
          const getdata ={
            "taskId": task,
            "body": cmt
          }

          $api.postApiData (url,utoken,successStat,errorStat,getdata);
          function successStat (res) {
            alert('문의 내용을 송부 하였습니다. 빠르게 답변 드리겠습니다.')
            /*--모달폼 닫기--*/
            $('#cancel').click()
            /*--새로고침--*/
            window.location.href="/accpage/history/1"
          }
          function errorStat (error) {
            alert('시스템 오류입니다. 문의내용을 송부하지 못했습니다.')
            /*--모달폼 닫기--*/
            $('#cancel').click()
            /*--새로고침--*/
            window.location.href="/accpage/history/1"
          }

        }
        else {
          alert("신고 내용은 10자이상 입력바랍니다.")
        }
      }
      else {
        alert("로그인 정보가 없습니다. 로그인 해 주십시오.");
      }
    },

    // SearchOptionValue() {
    //
    //   const selectedValue = $('.Searchbar').val();
    //   if (selectedValue === "1") {
    //     this.searchOption = "TITLE";
    //   } else if (selectedValue === "2") {
    //     this.searchOption = "NICKNAME";
    //   } else if (selectedValue === "3") {
    //     this.searchOption = "BODY";
    //   } else {
    //     this.searchOption = "";
    //   }
    //   // 선택된 값에 따라 할당된 문자열 출력
    // },

    // searchArticle(event){
    //   event.preventDefault();
    //
    //   const sc = $('#searchContext').val();
    //   const cat = this.searchOption;
    //
    //   this.$router.push({
    //     name: 'Comm_List_S',
    //     params: {
    //       page: 1
    //     },
    //     query: {
    //       "category": cat,
    //       "keyword": sc
    //     }
    //   });
    //
    // },


    subStringCmt(){
      const maxlength = 1000
      const sliceCmt = this.CmtContext.substring(0, maxlength)
      this.CmtContext = sliceCmt
      this.conLength = sliceCmt.length
      alert("작성 내용은 1000자를 넘을 수 없습니다.")
    }

  },


};

</script>

<style scoped>
.d-flex{
  margin: 0px 23%;
}
.w3-table td, .w3-table th, .w3-table-all td, .w3-table-all th {
  font-size:14px;
  text-align: center;
  line-height: 30px;

}

td a {
  cursor:pointer;
}
.td_style {
  width: 60%;
  text-align: left !important;
  padding-left: 20px;
}
.form-select {
  font-size: 13px;
  height: 33px;
}
.form-control {
  font-size: 13px;
  height: 33px;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none !important;
  /* box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); */
}

.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none !important;
  /* box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); */
}

.detail_btn {
  margin: 0px 10px;
  height: 22px;
  padding: 1px 10px;
  border: 0px;
  font-size: 12px;
  width: 85px;
  background-color: #2d2c2c;
}

.detail_btn_E {
  margin: 0px 10px;
  height: 22px;
  padding: 1px 10px;
  border: 0px;
  font-size: 12px;
  width: 85px;

}

.search {
  border-radius: 5px;
  color: white;
  font-size: 13px;
  height: 33px;}

.pa_table {
  margin-bottom: 20px;
}

.modal {
  --bs-modal-width:600px;
  --bs-modal-border-radius: 4px;
}
.modal-header {
  height: 40px;
}
.modal-header h1 { margin-top: 0px;}
.modal-body h4 {
  font-size: 17px;
  font-weight: bold;
}
.Cmt_Title{
  margin-bottom: 21px;
  margin-top: 15px;
  font-size: 13px;
}

.history_title{
  background-color: #e3e3e3;
  width: 100%;
  border-radius: 9px;
  padding-left: 8px;
  font-size: 14px;
  font-weight: bold;
}

.detail_used{
  color: #212529;
  font-size: 13px;
  padding-left: 15px;
  margin-top: 9px;
  line-height: 25px;
}

.workData{
  color:#3575d5;
  font-size:13px;
}

#Cmttextarea {
  margin-top:10px;
  margin-bottom:3px;
  resize:none;
  height:250px;
}

.modal_btn {
  width:100px;
  color:white;
  text-align: center;
  background-color: #00264a;
  border: 0px;
  height: 29px;
  padding-top: 3px;
}
.dn_document{
  border: 1px solid #a39f9f;
  border-radius: 15px;
  padding: 0px 25px 2px 25px;
}

.dn_document:hover {
  outline-width: 0;
  background-color: #1670bd1c;
}

.mo_acc_his_body {
  display:none;
}

</style>