import {Client} from "@stomp/stompjs";

const BASE_URL = "wss://autoclosing.ai/api/ws"
// 싱글톤 인스턴스를 저장할 변수
let token = null;
let instance = null;

let reconnectAttempts = 0;
const maxReconnectAttempts = 5;

function createInstance() {
    let client = null;
    let resolveConnectPromise = null; // 연결 완료 promise를 해결하기 위한 resolve 함수

    let taskResultCallback = null;

    function createAndActivateClient() {

        // 클라이언트가 이미 존재하고 활성화되어 있는지 확인
        if (client && client.connected) {
            resolveConnectPromise(client);
            return; // 이미 연결되어 있으므로 새로운 연결 시도 없음
        }

        if(token)
        {
            // 새로운 클라이언트 객체 생성
            client = new Client({
                brokerURL: BASE_URL + '/establish', // WebSocket 브로커 URL
                debug: function (str) {
                    // console.log(str); // 디버그 로그 출력 (옵션)
                },
                onConnect: function (){
                    reconnectAttempts = 0;
                    client.subscribe("/user/notification/taskResult", handleMessage);
                    resolveConnectPromise(client);
                },
                onStompError : function (error) {
                    // console.log(error);
                },
                onWebSocketError: function (error) {
                    // console.log(error);
                },
                onWebSocketClose: function (){
                    if (reconnectAttempts < maxReconnectAttempts) {
                        reconnectAttempts++;
                        client.reconnectDelay = 3000 * reconnectAttempts; // 재접속 딜레이 증가
                        console.log(`Reconnecting in ${client.reconnectDelay / 1000} seconds, attempt ${reconnectAttempts}`);
                    } else {
                        console.log("Max reconnect attempts reached.");
                        client.deactivate(); // 더 이상 재접속하지 않음
                    }
                },
                connectHeaders: {
                    Authorization: `Bearer ${token}` // JWT 토큰을 헤더에 추가
                },
                heartbeatIncoming:10000,
                heartbeatOutgoing:10000
            });
            // 클라이언트 활성화
            client.activate();
        }

    }
    async function getClient() {
        // 클라이언트가 없거나 비활성화된 경우 새로 생성하고 활성화
        if (!client || !client.connected) {
            // 새로운 연결 promise 생성
            const connectPromise = new Promise((resolve) => {
                resolveConnectPromise = resolve;
            });

            // 클라이언트 생성 및 연결 완료 기다림
            createAndActivateClient();

            // 연결 완료 기다림
            await connectPromise;
        }
        return client;
    }

    async function start(userToken){
        token = userToken
        await getClient(); // 현재 클라이언트 가져오
    }

    function stop()
    {
        if (!client || !client.connected) {
            client.deactivate()
        }
    }

    function setTaskResultHandler(handler)
    {
        taskResultCallback = handler
    }

    function handleMessage(message)
    {
        const body = message.body
        const bodyObj = JSON.parse(body)

        if(taskResultCallback)
        {
            taskResultCallback(bodyObj)
        }


        // if(bodyObj.md_user_nm)
        // {
        //     const userName = bodyObj.md_user_nm
        //     if(logData.has(userName))
        //     {
        //         //이미있는경우에는 로그데이터 배열 젤 앞쪽에 추가
        //         const targetArr = logData.get(userName)
        //         targetArr.unshift(bodyObj)
        //     }else{
        //         //신규 사용자 배열에 추가
        //         const logArr = [bodyObj];
        //         logData.set(userName,logArr)
        //         userProxy.push(userName)
        //     }
        //
        //     logSubscribeArr.forEach(subscriber => {
        //         if(subscriber.username === userName && subscriber.callback) {
        //             subscriber.callback(bodyObj)
        //         }
        //     })
        // }
    }


    return {
        setTaskResultHandler,
        stop,
        start
    };
}

// 싱글톤 인스턴스를 반환하는 함수
export function sharedSocketManager() {
    if (!instance) {
        instance = createInstance();
    }
    return instance;
}