<template>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
  <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css">
  <div>
    <router-view></router-view>
  </div>
</template>

<script>

import {computed, onMounted, onUnmounted, watch} from "vue";
import {sharedSocketManager} from "@/script/WebSocketMananger";


export default {
  //컴포지션 API
  setup(){
    //store에 있는 사용자 토큰을 userToken에 동적으로 바인딩한다.
    //computed로 선언시 store.state.token값이 바뀔때마다 userToken이 바뀐다.
    const userToken = computed(() => {
      return $store.getters.userToken
    })
    //실제 변화가 이루어졌을 경우 수행할 함수를 watch를 통해 엮는다
    watch(userToken,(newValue) => {
      alert("call Watch!!")
      console.log('store.getters.userToken!!',newValue)
    })

    sharedSocketManager().setTaskResultHandler( (result) => {
      $store.dispatch("setModuleResult",result);
    })


    //컴포넌트 마운트시
    onMounted( () => {
      console.log("hello app!!",userToken.value)
      if(userToken.value)
      {
        //로그인 토큰이 유효 하다면
        sharedSocketManager().start(userToken.value)
      }
    })

    onUnmounted(() => {
      sharedSocketManager().stop()
    })

    return {};
  }
}
</script>

<style>

</style>
