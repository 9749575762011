<template>
  <div>
    <div id="board_title" class="row">
      <div id="main_title">
        감사/검토보고서 DSD 검토 자동화
      </div>
      <div id="sub_title">
        감사/검토보고서 DSD Footing 자료를 업로드 하여 검토 자료를 작성합니다.
      </div>
    </div>
    <div class="Main_Contents" >
      <div style="margin-bottom:20px;">아래 [감사/검토보고서 UPLOAD] 버튼을 클릭하여, 준비된 DSD 검토 자료를 UPLOAD 합니다.<br>
        검토 결과 내용은 등록된 USER MAIL 계정으로 송부 됩니다.
      </div>

      <div class="dsd_MSG">
        현재 DSD Footing 서비스는 베타버전으로 감사전재무제표, 감사보고서, 검토보고서의 Footing이 가능합니다.<br>
        개별, 별도, 연결 재무제표 모든 형식이 가능하오니 많은 이용 바랍니다.<br>
        향후 재무수치, 재무제표-주석간 대사, 주석-주석 대사도 지속적으로 업데이트 될 예정이오니, 변경사항은 공지를 통해 전달드리겠습니다.
      </div>

      <div class="l_menus" v-if="workingStatus==='EMPTY'|| workingStatus==='SUCCESS'|| workingStatus==='ERROR' || workingStatus==='FAIL' || workingStatus===''">
        <div>
          <form method="POST" enctype="multipart/form-data" id="dsdForm">

            <label for="dsd-file" id="up_btn" class="guidebox">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" id="up_btn_icon" fill="currentColor" class="icons" viewBox="0 0 16 16">
                <path d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707V11.5z"/>
                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
              </svg><p>감사/검토보고서 UPLOAD</p>
            </label>

            <input class="file" type="file" id="dsd-file" accept=".dsd" name="multipartFile" @change="dsdUpload" ref="fileInput">
          </form>
        </div>

      </div>
      <div class="l_menus" v-if="workingStatus==='PENDING'|| workingStatus==='IDLE'|| workingStatus==='PROCESSING'">
        <div>
          <label for="dsd-file" id="up_btn" class="guidebox disabled">
            <img class="loading" src="../images/loading.gif"><p>작업을 처리중 입니다...</p>
          </label>

          <input class="file" type="file" id="dsd-file" accept=".dsd" name="multipartFile" @change="dsdUpload" disabled>

        </div>
      </div>

      <!--산출 성공-->
      <div class="finished_run"  v-if="workingStatus==='SUCCESS'" >
        <div>
          <span><span style="color:#0e427c">{{user}}</span>님의 최종 작업 내역입니다.</span>
        </div>
        <div class="run_detail" v-if="workingDate">
          작업 일자: <span style="color:#0e2238">{{workingDate}}</span>

        </div>
        <div style="color:#0c83e1;" >
          <span>*작업시 업로드한 DSD 파일과 송부된 엑셀 파일은 바로 삭제되며, 남아있지 않음을 안내 드립니다.</span>
        </div>
      </div>

      <!--      산출물 검증중-->
      <div class="finished_run"  v-if="workingStatus==='IDLE'" >
        <div>
          <span>현재 <span style="color:#0e427c">{{user}}</span>님의 검증중인 작업이 있습니다.</span>
        </div>
        <div class="run_detail">
          요청 일자: <span style="color:#0e2238">{{workingDate}}</span>

        </div>
        <div style="color:#0c83e1;" >
          <span>*작업이 끝난 후 다시 업로드 가능합니다. 잠시만 기다려 주세요.</span>
        </div>
<!--        <button class="btn btn-success resetbtn" @click="reset_form">작업 새로고침</button>-->
      </div>

      <!--      산출물 대기중-->
      <div class="finished_run"  v-if="workingStatus==='PENDING'" >
        <div>
          <span>현재 <span style="color:#0e427c">{{user}}</span>님의 대기중인 작업이 있습니다.</span>
        </div>
        <div class="run_detail">
          요청 일자: <span style="color:#0e2238">{{workingDate}}</span>

        </div>
        <div style="color:#0c83e1;" >
          <span>*작업이 끝난 후 다시 업로드 가능합니다. 잠시만 기다려 주세요.</span>
        </div>
<!--        <button class="btn btn-success resetbtn" @click="reset_form">작업 새로고침</button>-->
      </div>

      <!--      산출물 처리중-->
      <div class="finished_run"  v-if="workingStatus==='PROCESSING'" >
        <div>
          <span>현재 <span style="color:#0e427c">{{user}}</span>님의 처리중인 작업이 있습니다.</span>
        </div>
        <div class="run_detail">
          요청 일자: <span style="color:#0e2238">{{workingDate}}</span>

        </div>
        <div style="color:#0c83e1;" >
          <span>*작업이 끝난 후 다시 업로드 가능합니다. 잠시만 기다려 주세요</span>
        </div>
<!--        <button class="btn btn-success resetbtn" @click="reset_form">작업 새로고침</button>-->
      </div>

      <!--      실행에러 01 -->
      <div class="finished_run"  v-if="workingStatus==='ERROR'" >
        <div>
          <span><span style="color:#0e427c">{{user}}</span>님의 최종 작업 중 오류가 발생하였습니다.</span>
        </div>
        <div class="run_detail">
          요청 일자: <span style="color:#0e2238">{{workingDate}}</span>

        </div>
        <div style="color:#0c83e1;" >
           <span>감사/검토보고서 DSD Footing 모듈 구동 중 오류가 발생하였습니다.</span><br>
        </div>
        <div class="msg_sub">
          <span >감사/검토보고서 DSD 파일에 오류가 있거나 처음 구동하는 경우 DSD 양식에 대한 검토가 필요할 수 있으므로 <br>아래 내용을 참고하여 전기 DSD 제시를 통한 [DSD 검토 요청]을 하시기 바랍니다.</span>
        </div>

      </div>

      <!--      실행에러 02 -->
      <div class="finished_run"  v-if="workingStatus==='FAIL'" >
        <div>
          <span><span style="color:#0e427c">{{user}}</span>님의 입력 내용에 오류가 있습니다.</span>
        </div>
        <div class="run_detail">
          요청 일자: <span style="color:#0e2238">{{workingDate}}</span>

        </div>
        <div style="color:#0c83e1;" >
          <span>감사/검토보고서 DSD Footing 입력 내용에서 오류가 발생하였습니다.</span><br>
        </div>

        <div v-if="failMsg">
          <ul>
            <li v-for="(msg,index) in failMsg" :key="index">{{msg}}</li>
          </ul>
        </div>

<!--        <div class="msg_sub">-->
<!--          <span >감사/검토보고서 DSD 내용에 수정이 필요한 사항이 있어, 관련 내용을 메일로 송부 드렸습니다. <br>전달드린 내용을 수정하시어 재업로드를 부탁드립니다.</span>-->
<!--        </div>-->

      </div>
<!-- DSD 검토 요청-->

      <div class="dsd_inspection" v-if="workingStatus==='ERROR'">
        <div class="ins_btn" type=button @click="insReq" style="display: inline">
          전기 DSD 검토 요청</div>

        <span style="font-size: 13px;">*전기 DSD 양식파일을 업로드해 주시면, 작업 가능여부를 확인하여 답변드립니다.</span>

      </div>

<!--     가이드 페이지 링크 -->
      <div class="l_menus guide_pdf" style="margin-bottom: 10px;">
        <router-link to="/svcs/dsdfg" >
          <p>가이드 PAGE</p>
        </router-link>
      </div>
      DSD Footing 자동화 서비스의 안내 정보
      <span style="font-size: 12px; font-weight: 800;">(클릭하여 PAGE 이동)</span>

    </div>

    <!--failMsg 모달폼-->
    <button type="button"  style="display: none" id="fail_Msg_modal" class="btn btn-primary detail_btn" data-bs-toggle="modal" data-bs-target="#fail_Msg">메세지</button>
    <div class="modal fade" id="fail_Msg" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" style="max-width: max-content; margin:auto">
        <div class="modal-content" >
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Lease Fail Message</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" style="padding-right: 33px;">
            <ul>
              <li v-for="(msg,index) in failMsg" :key="index">{{msg}}</li>
<!--              <li >메세지 이벤트 모듈 실패 처리 메세지를 2줄로 표시되지 않도록 처리합니다. 2줄이 표시되도록 확인합니다.</li>-->
            </ul>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">확인</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import {computed, getCurrentInstance, watch} from "vue";

export default {
  name: "dsdFooter_run",
  setup(){
    const instance = getCurrentInstance(); // 현재 컴포넌트 인스턴스 가져오기
    const proxy = instance.proxy; // `data`에 접근하려면 `instance.proxy`를 사용합니다.
    const moduleResult = computed(() => {
      return $store.getters.dsdResult
    })

    watch(moduleResult,(result) => {
      if(result.resultType === 'FAIL')
      {
        if(result.failMsg)
        {
          proxy.failMsg = result.failMsg
          // alert(result.failMsg.join('\n'))
          console.log("fail")
          $('#fail_Msg_modal').click()
        }
      }
      proxy.workingStatus = result.resultType
      setTimeout(() => {
        proxy.getDsdWork(); // `methods`의 getDsdWork 호출
      }, 1500); // 1500밀리초(1.5초) 지연

    })

  },


  data(){
    return {
      failMsg:null,
      finished : true,
      workingDate:'',
      workingStatus:'',
      user: $store.state.user_log,
      insRequest: false,
      access: !!localStorage.getItem("access-token")
    }
  },
  mounted(){
    if($store.state.token != null){
      this.getDsdWork()
    }
    else{
      this.workingStatus='';
    }
  },

  methods:{

    getDsdWork: function() {
      const t=this
      const url = '/dsd/dsdStatus'
      const utoken =$store.state.token != null;
      $api.getApiData(url,utoken,successStat,errorStat);

      function successStat (res) {
        const Status= res.data.data
        t.workingStatus = Status.taskStatus;
        t.workingDate = Status.createDate;

      }
      function errorStat (error) {
        alert("유저 작업 정보를 가져오지 못했습니다. 페이지를 새로고침 해 주세요.")

      }

    },

    insReq: function(){
      window.open('/dsdchk','_blank',"menubar=no, toolbar=no, width=450px,height=550px,top=200,left=700")

    },

    dsdUpload: function (event) {
      this.$gtag.event('DSDRun(U)Click',{
        event_category: 'Clicks',
        event_label: 'Counts',
        value: 'DSD Upload'
      })
      let a = localStorage.getItem("access-token")
      if( a != null){
        let b = localStorage.getItem("mobileAuth")
        const t = this
        if(b === "true"){
          t.dsdRun(event)
        }
        else{
          alert("본인 인증이 필요한 기능입니다. [마이페이지]에서 본인인증을 완료해 주세요");
          window.location.href="/accpage/myinfo"
        }

      }
      else{ alert("로그인 정보가 없습니다. 로그인 페이지로 이동합니다.");
        window.location.href="/login"}

    },

    dsdRun(event){
      const file = event.target.files[0];
      const url = '/dsd/uploadDsdFile'
      const utoken =$store.state.token != null;
      const inputdata ={
        "inputFile": file
      }


      if(file)
      {
        $api.uploadApiData (url,utoken,successStat,errorStat,inputdata);
        this.workingStatus = 'PENDING'
        this.$refs.fileInput.value = '';
      }

      function successStat (res) {
        // alert('파일을 업로드 하였습니다.');

      }
      function errorStat (error) {

        alert('파일 업로드를 실패 하였습니다.');

      }

    },


  }
}
</script>

<style scoped>
.dsd_MSG {
  margin-bottom: 60px;
  border: 1px solid rgb(33 64 106 / 26%);
  width: 50%;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  color: #3d5f85;
}

.guide_pdf{
  margin-bottom:10px;
  background-color: #0e2238;
  width:120px; height:30px;
  text-align:center;
  padding-top: 4px;
  color:white;
  border-radius: 20px;
  cursor: pointer;
}

.guide_pdf p:hover {
  color:#c6cfdd !important;
}
.l_menus{
  margin-bottom: 65px;
  font-size: 14px;
}


.icons{
  display: flex;
  width: 53px;
  background-color: #fff;
  height: 44px;
  padding: 6px;
  margin: auto 8px;
  border-radius: 25px;
}

.guidebox{
  display: flex;
  background-color: #dee2e6;
  width:265px;
  height:55px;
  border-radius: 35px;
  margin-bottom: 10px;
}

.disabled{
  display: flex;
  background-color: #cbd1d7;
  width:265px;
  height:55px;
  border-radius: 35px;
  margin-bottom: 10px;
}

.guidebox p {
  font-weight: 800;
  font-size: 13px;
  margin: auto 20px;

}

.disabled p {
  font-weight: 800;
  font-size: 13px;
  margin: auto 20px;
  color: #29486b;
}

.file {display: none;}

#dsdForm label{
  cursor:pointer;
}

.finished_run {
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
  max-width: max-content;
  margin: 40px 0px;
  padding: 10px 20px 10px 10px;
  background-color: #f5f5f5;
  border-radius: 7px;
}

.l_menus a{
  text-decoration: none;
}

.resetbtn {
  font-size: 14px;
  border-radius: 25px;
  width: 61px;
  padding-top: 3px;
  margin-left: 2px;
  background-color: transparent;
  color: black;
  padding-top: 7px;
  margin-top: 7px;
  width: 130px;
}

.run_detail{
  color:grey;
}
.run_btn{
  font-size: 12px;
  background-color: #0e2238;
  color: white;
  text-decoration: none;
  padding: 5px 15px;
  border-radius: 30px;
  line-height: 45px;
  margin-left: 10px;
  margin-right: 6px;
}

.loading{
  width: 49px;
  height: 46px;
  border-radius: 42px;
  padding: 1px;
  margin-top: 5px;
  margin-left: 7px;
}

.msg_sub{
  color: #0e2238;
  font-size: 13px;
  margin-top: 10px;
}

.dsd_inspection {
  width: 40%;
  margin: 30px 0px;
  padding: 20px 0px;
  border-top:1px solid #d7d7d7;
  border-bottom:1px solid #d7d7d7;
}

.ins_btn{
  width: 130px;
  height: 35px;
  background: #00295d;
  text-align: center;
  padding-top: 8px;
  font-size: 14px;
  margin-right: 15px;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
}


.modal {
  --bs-modal-width:500px;
  --bs-modal-border-radius: 4px;
}

.modal-title{
  font-family: 'Pretendard-Regular';
}

.modal-header {
  height: 40px;
}
.modal-header h1 {
  margin-top: 0px;
  font-size: 15px !important;
}
.modal-body h4 {
  font-size: 17px;
  font-weight: bold;
}
.modal-footer {
  height: 40px;
  padding: 4px;
}
.modal-footer button {
  height: 26px;
  padding: 2px;
  width: 84px;
}


</style>