import { createApp } from 'vue'
import App from './App.vue'
import BootstrapVue3 from 'bootstrap-vue-3'
import jQuery from 'jquery'
import Router from './router.js'
import store from './script/store/store'
import axios from "axios";
import VueGtag from "vue-gtag";
import {getApiData, postApiData,uploadApiData} from "./api"

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'


global.$ = jQuery
global.$store = store;
global.$axios = axios;
global.$api = {getApiData,postApiData,uploadApiData}

createApp(App).use(BootstrapVue3)
    .use(Router)
    .use(store)
    .use(VueGtag,{
        config: {
            id: "G-7PEX44CN67",
            params:{
                send_page_view: false,
            }
        }
    },Router)
    .mount('#app')
